import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside forgotPassword functional component
 */

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		height: '100vh',
		overflowX: 'hidden',
	},
	grid: {
		[theme.breakpoints.down('sm')]: {
			marginLeft: theme.spacing(-37.5 / 8),
		},
	},
	gutter: {
		width: 'calc(100% - 15px)',
		[theme.breakpoints.down('md')]: {
			width: 'calc(100% + 7.5px)',
		},
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100%)',
		},
	},
	dontHaveAccountTitle: {
		fontSize: '1rem',
		marginRight: '1.25rem',
		paddingTop: '0.5rem',
		color: '#7e7e80',
	},
	startHereButton: {
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: '1rem',
		borderColor: theme.palette.primary.main,
		'&:hover': {
			borderColor: theme.palette.primary.main,
			backgroundColor: '#E6E6EF',
		},
		'&:active': {
			borderColor: theme.palette.primary.main,
			backgroundColor: '#D7D1E5',
		},
	},
	logo: {
		height: '40px',
		width: '114px',
		marginTop: '2.1875rem',
		marginLeft: '2.5rem',
		position: 'absolute',
		[theme.breakpoints.down('md')]: {
			marginLeft: '4.125rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '2rem',
			marginTop: '1.3125rem',
		},
	},
	title: {
		fontWeight: 'bold',
		fontSize: '1.5rem',
		marginBottom: '0.75rem',
	},
	subTitle: {
		color: '#7e7e80',
	},
	titleBox: {},
	formActionBar: {
		marginTop: '3.3125rem',
	},
	formActionButton: {
		marginTop: theme.spacing(5),
		padding: theme.spacing(1.5, 0),
	},
	backButton: {
		color: '#08080d',
	},
	boldEmail: {
		fontWeight: 'bold',
		color: '#7e7e80',
	},
	validationBox: {
		marginTop: theme.spacing(3.25),
	},
	input: {
		marginTop: theme.spacing(24 / 8),
	},
	centerContainer: {
		marginTop: theme.spacing(120 / 8),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(88 / 8),
		},
	},
	successButtonContainer: {
		marginLeft: 7.5,
		[theme.breakpoints.down('md')]: {
			marginLeft: 0,
		},
	},
	mailIcon: {
		height: theme.spacing(174 / 8),
		width: theme.spacing(180 / 8),
		marginLeft: '7%',
		[theme.breakpoints.down('md')]: {
			marginLeft: '15%',
		},
	},
	centeredText: {
		textAlign: 'center',
	},
	titleBig: {
		fontSize: '2.25rem',
		marginTop: theme.spacing(31 / 8),
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.5rem',
		},
	},
	errorMessageBox: {
		margin: theme.spacing(3, 0),
	},
}))

export default useStyles
