import {Box, Grid, Typography} from '@material-ui/core'
import classNames from 'classnames'
import {Formik} from 'formik'
import PropTypes from 'prop-types'
import React, {ReactNode, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import ValidationChipBox from '@components/ValidationChipBox'
import {
	CustomButtonComponent as CustomButton,
	ErrorMessageBoxComponent as ErrorMessageBox,
	PasswordInputComponent as PasswordInput,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {CyclisLogoSvg} from '@svg/index'
import {resetPassword} from '@templates/ResetPassword/reset-password-slice'
import {
	TEN_CHARS_REGEX,
	TWO_DIGIT_REGEX,
	TWO_SPECIAL_CHARS_REGEX,
	TWO_UPPERCASE_REGEX,
} from '@utils/constants/regex-constants'
import {LanguageContext} from '@utils/context'
import {CommonProps} from '@utils/types'

import {resetPasswordSchema} from './reset-password-validation'
import useStyles from './style.hook'

/**
 * ResetPassword
 */
interface ResetPasswordProps extends CommonProps {
	notRequiredPlaceholderProp?: string
}

interface ResetPasswordFormFields {
	password: string
}

const ResetPassword: React.FC<ResetPasswordProps> = ({location}) => {
	const RESET_TOKEN_QUERY_PARAM = 'token'
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const dispatch = useDispatch()
	const {t} = useTranslation(undefined, {useSuspense: false})

	const [resetToken, setResetToken] = useState<string>()
	const {activeLanguage} = useContext(LanguageContext)

	const resetPasswordLoading = useSelector(
		(state: RootState) => state.resetPassword.loading
	)
	const resetPasswordSuccess = useSelector(
		(state: RootState) => state.resetPassword.success
	)
	const resetPasswordError = useSelector(
		(state: RootState) => state.resetPassword.error
	)

	const initialValues = {password: '', currentPassword: ''}

	const submitForgotPassword = async (
		values: ResetPasswordFormFields
	): Promise<void> => {
		dispatch(resetPassword(values.password, resetToken!))
	}

	/**
	 * Parse reset token on page load
	 */
	useEffect(() => {
		const {search} = location!
		const params = new URLSearchParams(search)
		const receivedAuthToken = params.get(RESET_TOKEN_QUERY_PARAM)

		if (!receivedAuthToken || !receivedAuthToken.length) {
			return
		}

		setResetToken(params.get(RESET_TOKEN_QUERY_PARAM)!)
	}, [location])

	/**
	 * Navigate to login page on successful reset
	 */
	useEffect(() => {
		if (resetPasswordSuccess) {
			dispatch(navigate(`/${activeLanguage}/login`))
		}
	}, [resetPasswordSuccess, activeLanguage])

	const ResetPasswordForm: React.FC = () => (
		<Box className={classes.centerContainer}>
			<Box className={classNames(classes.titleBox, classes.gutter)}>
				<Typography variant={'h2'} className={classes.title}>
					{t('ResetPasswordTitle')}
				</Typography>
				<Typography className={classes.subTitle} variant={'body1'}>
					{t('ResetPasswordSubTitle')}
				</Typography>
			</Box>
			{resetPasswordError && (
				<ErrorMessageBox
					id="reset-password-error-message-box"
					className={classes.errorMessageBox}
					errorMessage={t(resetPasswordError)}
				/>
			)}
			<Formik
				initialValues={initialValues}
				validationSchema={resetPasswordSchema(t)}
				onSubmit={submitForgotPassword}
			>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					touched,
					errors,
				}): ReactNode => (
					<form onSubmit={handleSubmit}>
						<PasswordInput
							label={t('LoginPasswordLabel')}
							className={classes.input}
							helperText={(touched.password && errors.password) as string}
							helperTextId={errors.password && 'password-helper-text'}
							propsToDelegate={{
								name: 'password',
								type: 'password',
								value: values.password,
								onChange: handleChange,
								onBlur: handleBlur,
								fullWidth: true,
							}}
						/>
						<ValidationChipBox
							className={classes.validationBox}
							validations={[
								{
									label: t('SetPasswordTwoSpecialCharacters'),
									value: values.password,
									regex: TWO_SPECIAL_CHARS_REGEX,
								},
								{
									label: t('SetPasswordTwoUppercaseCharacters'),
									value: values.password,
									regex: TWO_UPPERCASE_REGEX,
								},
								{
									label: t('SetPasswordTwoDigits'),
									value: values.password,
									regex: TWO_DIGIT_REGEX,
								},
								{
									label: t('SetPasswordTenCharacters'),
									value: values.password,
									regex: TEN_CHARS_REGEX,
								},
							]}
						/>
						<Grid item md={6}>
							<CustomButton
								id={'reset-password-save-button'}
								className={classNames(classes.formActionButton, classes.gutter)}
								text={t('ResetPasswordSaveButton')}
								propsToDelegate={{
									type: 'submit',
									disabled: resetPasswordLoading,
									fullWidth: true,
								}}
							/>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	)

	return (
		<Box className={classes.mainContainer}>
			<CyclisLogoSvg className={classes.logo} color />
			<Grid className={classes.grid} spacing={6} container direction="row">
				<Grid item md={4} sm={2} xs={1} />
				<Grid item md={4} sm={8} xs={10}>
					<ResetPasswordForm />
				</Grid>
				<Grid item md={4} sm={2} xs={1} />
			</Grid>
		</Box>
	)
}

ResetPassword.propTypes = {
	location: PropTypes.any,
}

export default ResetPassword
