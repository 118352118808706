import {TFunction} from 'i18next'
import * as Yup from 'yup'

import {
	TEN_CHARS_REGEX,
	TWO_DIGIT_REGEX,
	TWO_SPECIAL_CHARS_REGEX,
	TWO_UPPERCASE_REGEX,
} from '@utils/constants/regex-constants'
import {FormValidationErrorMessages} from '@utils/types/form-validation-error-messages'

export const resetPasswordSchema = (t: TFunction): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		password: Yup.string()
			.matches(
				TWO_SPECIAL_CHARS_REGEX,
				t(FormValidationErrorMessages.PATTERN_MATCH)
			)
			.matches(
				TWO_UPPERCASE_REGEX,
				t(FormValidationErrorMessages.PATTERN_MATCH)
			)
			.matches(TWO_DIGIT_REGEX, t(FormValidationErrorMessages.PATTERN_MATCH))
			.matches(TEN_CHARS_REGEX, t(FormValidationErrorMessages.PATTERN_MATCH))
			.required(t(FormValidationErrorMessages.REQUIRED)),
	})
